/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */
// global.scss

// // global.scss
// @import '~swiper/scss';
// @import '~@ionic/angular/css/ionic-swiper';

// // global.scss
// @import '~swiper/scss';
// @import '~swiper/scss/autoplay';
// @import '~swiper/scss/keyboard';
// @import '~swiper/scss/pagination';
// @import '~swiper/scss/scrollbar';
// @import '~swiper/scss/zoom';
// @import '~@ionic/angular/css/ionic-swiper';

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

// VideoJS css -> 7.21.x  // Usar de vide.js/dist/video-js.min.css
@import "video-js.css";

@font-face {
  font-family: goodtimes;
  src: url(assets/font/goodtimes.otf) format("opentype");
}

ion-content {
  --ion-background-color: none !important;
  --background: radial-gradient(circle at center, #1f0183 0%,  #160647 60%);

  @media (max-width: 991px) {
    --background: radial-gradient(circle at 50% 40%, #3b0fc9 0%,  #160647 60%);
  }
  

  ion-toolbar, ion-menu-toggle, ion-list, ion-item, ion-label, .list-ios {
      background: transparent !important;
  }
}

ion-item {
  --background: none !important
}

ion-modal::part(backdrop) {
    background: rgb(223, 225, 226);
    opacity: 0.1;
 }
 
ion-modal {
    --background: #13064b !important;
}

ion-action-sheet.my-custom-class-actionSheet .action-sheet-group {
    background: #13064b;
}

ion-action-sheet.my-custom-class-actionSheet .action-sheet-title {
  color: #fff;
}

ion-action-sheet.my-custom-class-actionSheet .action-sheet-cancel::after {
  background: #13064b;
}

ion-action-sheet.my-custom-class-actionSheet .action-sheet-button,
ion-action-sheet.my-custom-class-actionSheet .action-sheet-button.ion-focused {
  color: #fff;
}

@media (any-hover: hover) {
  ion-action-sheet.my-custom-class-actionSheet .action-sheet-button:hover {
    color: #26166d;

  }
}

ion-action-sheet.my-custom-class-actionSheet ion-backdrop {
  opacity: 0.6;
  background-color: #26166d;
}

.no-capitalize{
  text-transform: none;
}

.mt-10 {
  margin-top: 10px
}

.mr-10 {
  margin-right: 10px;
}

.pt-2 {
  padding-top: 1em;
}