// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/

* ::-webkit-scrollbar {
  display: none;
}

ion-button {
  text-transform: none;
  letter-spacing: -0.01rem;
}

ion-select {
  --placeholder-color: #FFF;
  --placeholder-opacity: 1;
  width: 100%;
  justify-content: center;    
}

@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;600;700&display=swap');

:root {

  --ion-font-family: 'Roboto', sans-serif;

  /** primary **/
  --ion-color-primary: #00b8e6;
	--ion-color-primary-rgb: 0,184,230;
	--ion-color-primary-contrast: #000000;
	--ion-color-primary-contrast-rgb: 0,0,0;
	--ion-color-primary-shade: #00a2ca;
	--ion-color-primary-tint: #1abfe9;

	--ion-color-secondary: #fd54e3;
	--ion-color-secondary-rgb: 127,50,212;
	--ion-color-secondary-contrast: #ffffff;
	--ion-color-secondary-contrast-rgb: 255,255,255;
	--ion-color-secondary-shade: #aa0797;
	--ion-color-secondary-tint: #ef3cda;

	--ion-color-tertiary: #b4002c;
	--ion-color-tertiary-rgb: 180,0,44;
	--ion-color-tertiary-contrast: #ffffff;
	--ion-color-tertiary-contrast-rgb: 255,255,255;
	--ion-color-tertiary-shade: #9e0027;
	--ion-color-tertiary-tint: #bc1a41;

	--ion-color-success: #2dd36f;
	--ion-color-success-rgb: 45,211,111;
	--ion-color-success-contrast: #000000;
	--ion-color-success-contrast-rgb: 0,0,0;
	--ion-color-success-shade: #28ba62;
	--ion-color-success-tint: #42d77d;

	--ion-color-warning: #ffc409;
	--ion-color-warning-rgb: 255,196,9;
	--ion-color-warning-contrast: #000000;
	--ion-color-warning-contrast-rgb: 0,0,0;
	--ion-color-warning-shade: #e0ac08;
	--ion-color-warning-tint: #ffca22;

	--ion-color-danger: #eb445a;
	--ion-color-danger-rgb: 235,68,90;
	--ion-color-danger-contrast: #ffffff;
	--ion-color-danger-contrast-rgb: 255,255,255;
	--ion-color-danger-shade: #cf3c4f;
	--ion-color-danger-tint: #ed576b;

	--ion-color-medium: #92949c;
	--ion-color-medium-rgb: 146,148,156;
	--ion-color-medium-contrast: #000000;
	--ion-color-medium-contrast-rgb: 0,0,0;
	--ion-color-medium-shade: #808289;
	--ion-color-medium-tint: #9d9fa6;

	--ion-color-light: #f7f7f7;
	--ion-color-light-rgb: 247,247,247;
	--ion-color-light-contrast: #000000;
	--ion-color-light-contrast-rgb: 0,0,0;
	--ion-color-light-shade: #d9d9d9;
	--ion-color-light-tint: #f8f8f8;

  --ion-color-btn-text-white: #ffffff;
	--ion-color-btn-text-white-rgb: 255,255,255;
	--ion-color-btn-text-white-contrast: #000000;
	--ion-color-btn-text-white-contrast-rgb: 0,0,0;
	--ion-color-btn-text-white-shade: #e0e0e0;
	--ion-color-btn-text-white-tint: #ffffff;

  --ion-color-bg-purple: #13064b;
	--ion-color-bg-purple-rgb: 19,6,75;
	--ion-color-bg-purple-contrast: #ffffff;
	--ion-color-bg-purple-contrast-rgb: 255,255,255;
	--ion-color-bg-purple-shade: #110542;
	--ion-color-bg-purple-tint: #2b1f5d;

	--ion-color-btn-primary-white: #00b8e6;
	--ion-color-btn-primary-white-rgb: 0,184,230;
	--ion-color-btn-primary-white-contrast: #fff;
	--ion-color-btn-primary-white-contrast-rgb: 0,0,0;
	--ion-color-btn-primary-white-shade: #00a2ca;
	--ion-color-btn-primary-white-tint: #1abfe9;
}


.ios body {
  --ion-background-color: #13064b;
  --ion-background-color-rgb: 0,0,0;

  --ion-text-color: #ffffff;
  --ion-text-color-rgb: 255,255,255;

  --ion-color-step-50: #0d0d0d;
  --ion-color-step-100: #1a1a1a;
  --ion-color-step-150: #262626;
  --ion-color-step-200: #333333;
  --ion-color-step-250: #404040;
  --ion-color-step-300: #4d4d4d;
  --ion-color-step-350: #595959;
  --ion-color-step-400: #666666;
  --ion-color-step-450: #737373;
  --ion-color-step-500: #808080;
  --ion-color-step-550: #8c8c8c;
  --ion-color-step-600: #999999;
  --ion-color-step-650: #a6a6a6;
  --ion-color-step-700: #b3b3b3;
  --ion-color-step-750: #bfbfbf;
  --ion-color-step-800: #cccccc;
  --ion-color-step-850: #d9d9d9;
  --ion-color-step-900: #e6e6e6;
  --ion-color-step-950: #f2f2f2;

  --ion-item-background: #000000;

  --ion-card-background: #1c1c1d;
}

.ion-color-btn-text-white {
	--ion-color-base: var(--ion-color-btn-text-white);
	--ion-color-base-rgb: var(--ion-color-btn-text-white-rgb);
	--ion-color-contrast: var(--ion-color-btn-text-white-contrast);
	--ion-color-contrast-rgb: var(--ion-color-btn-text-white-contrast-rgb);
	--ion-color-shade: var(--ion-color-btn-text-white-shade);
	--ion-color-tint: var(--ion-color-btn-text-white-tint);
}

.ion-color-bg-purple {
	--ion-color-base: var(--ion-color-bg-purple);
	--ion-color-base-rgb: var(--ion-color-bg-purple-rgb);
	--ion-color-contrast: var(--ion-color-bg-purple-contrast);
	--ion-color-contrast-rgb: var(--ion-color-bg-purple-contrast-rgb);
	--ion-color-shade: var(--ion-color-bg-purple-shade);
	--ion-color-tint: var(--ion-color-bg-purple-tint);
}

.ion-color-btn-primary-white {
	--ion-color-base: var(--ion-color-btn-primary-white);
	--ion-color-base-rgb: var(--ion-color-btn-primary-white-rgb);
	--ion-color-contrast: var(--ion-color-btn-primary-white-contrast);
	--ion-color-contrast-rgb: var(--ion-color-btn-primary-white-contrast-rgb);
	--ion-color-shade: var(--ion-color-btn-primary-white-shade);
	--ion-color-tint: var(--ion-color-btn-primary-white-tint);
}

.ios ion-modal {
  --ion-background-color: var(--ion-color-step-100);
  --ion-toolbar-background: var(--ion-color-step-150);
  --ion-toolbar-border-color: var(--ion-color-step-250);
}


/*
* Material Design Dark Theme
* -------------------------------------------
*/

.md body {
  --ion-background-color: #121212;
  --ion-background-color-rgb: 18,18,18;

  --ion-text-color: #ffffff;
  --ion-text-color-rgb: 255,255,255;

  --ion-border-color: #222222;

  --ion-color-step-50: #1e1e1e;
  --ion-color-step-100: #2a2a2a;
  --ion-color-step-150: #363636;
  --ion-color-step-200: #414141;
  --ion-color-step-250: #4d4d4d;
  --ion-color-step-300: #595959;
  --ion-color-step-350: #656565;
  --ion-color-step-400: #717171;
  --ion-color-step-450: #7d7d7d;
  --ion-color-step-500: #898989;
  --ion-color-step-550: #949494;
  --ion-color-step-600: #a0a0a0;
  --ion-color-step-650: #acacac;
  --ion-color-step-700: #b8b8b8;
  --ion-color-step-750: #c4c4c4;
  --ion-color-step-800: #d0d0d0;
  --ion-color-step-850: #dbdbdb;
  --ion-color-step-900: #e7e7e7;
  --ion-color-step-950: #f3f3f3;

  --ion-item-background: #1e1e1e;

  --ion-toolbar-background: #1f1f1f;

  --ion-tab-bar-background: #1f1f1f;

  --ion-card-background: #1e1e1e;
}

.c-white{
	color: var(--ion-color-btn-text-white);
}
